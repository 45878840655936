import { Injectable } from '@angular/core';
import { RestService } from '@innova2/ngx-http-helper';
import { User } from '../../dtos/user';

@Injectable({
    providedIn: 'root',
})
export class UserService extends RestService<User> {
    protected override readonly resourceUri = 'users';
}
