import { Component, OnDestroy, OnInit } from '@angular/core';
import { CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { Store } from '@ngrx/store';
import {
    UserFeatureSelectors,
    UserFeatureState,
} from '../../../user/user.store';
import AuthActions from '../../../user/actions/auth.actions';
import { LS_AUTH } from '../../constants';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-profile-toolbar',
    templateUrl: './profile-toolbar.component.html',
    styleUrls: ['./profile-toolbar.component.scss'],
})
export class ProfileToolbarComponent implements OnInit, OnDestroy {
    private static readonly NOTIFY_TIME = 60 * 5; // 5 min

    user$ = this.store.select(UserFeatureSelectors.selectUser);

    tokenExpirationSubscription: Subscription;

    showCountdown = false;
    countdownAlmostDone = false;
    countdownConfig: CountdownConfig = {
        notify: ProfileToolbarComponent.NOTIFY_TIME,
    };

    isProfileOverlayOpen = false;

    constructor(private store: Store<UserFeatureState>) {}

    ngOnInit() {
        this.tokenExpirationSubscription = this.store
            .select(UserFeatureSelectors.selectTokenExpiration)
            .subscribe((exp) => {
                this.countdownConfig.leftTime = exp - Date.now() / 1000;
            });
    }

    ngOnDestroy() {
        this.tokenExpirationSubscription?.unsubscribe();
    }

    handleCountdown({ action, left }: CountdownEvent) {
        if (action === 'done') {
            this.disconnect();
        }

        this.countdownAlmostDone =
            action === 'notify' &&
            left / 1000 <= ProfileToolbarComponent.NOTIFY_TIME;
    }

    disconnect() {
        this.store.dispatch(AuthActions.disconnect());
        localStorage.removeItem(LS_AUTH);
    }
}
