import { createAction, props } from '@ngrx/store';
import { User } from '../dtos/user';

const getMe = createAction('user/getMe');
const getMeSuccess = createAction('user/getMeSuccess', props<{ user: User }>());

const reset = createAction('user/reset');

export default {
    getMe,
    getMeSuccess,
    reset,
};
