import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import {
    userFeatureEffects,
    userFeatureKey,
    userFeatureReducers,
} from './user.store';
import { EffectsModule } from '@ngrx/effects';

@NgModule({
    declarations: [LoginComponent],
    exports: [LoginComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        StoreModule.forFeature(userFeatureKey, userFeatureReducers),
        EffectsModule.forFeature(userFeatureEffects),
    ],
})
export class UserModule {}
