import { Component, HostListener, OnInit } from '@angular/core';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    displayIcons = false;
    isMobile = false;

    ngOnInit(): void {
        this.isMobile = window.outerWidth < 375;
    }

    @HostListener('window:resize')
    onResize() {
        this.isMobile = window.outerWidth < 375;
    }
}
