import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BreadcrumbItem } from '../../dtos/breadcrumb-item';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
    @Output() titleChange = new EventEmitter<string>();

    initialItem = {
        label: 'Pages',
        path: 'pages',
    };
    items: BreadcrumbItem[] = [];

    constructor(private router: Router, private route: ActivatedRoute) {}

    ngOnInit() {
        this.initializeBreadcrumAndTitle()

        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(() => this.initializeBreadcrumAndTitle());
    }

    initializeBreadcrumAndTitle() {
        this.items = this.createBreadcrumbs(this.route.root, '', [
            this.initialItem,
        ]);

        this.titleChange.emit(this.items[this.items.length - 1].label);
    }

    private createBreadcrumbs(
        route: ActivatedRoute,
        path: string = '',
        breadcrumbs: BreadcrumbItem[] = []
    ): BreadcrumbItem[] {
        const children: ActivatedRoute[] = route.children;

        if (!children.length) {
            return breadcrumbs;
        }

        const child = children[0];

        const routeURL = child.snapshot.url
            .map((segment) => segment.path)
            .join('/');
        if (routeURL) {
            path += `/${routeURL}`;
        }

        const labelOrItems = child.snapshot.data['breadcrumb'];
        if (labelOrItems) {
            const itemsToAdd: BreadcrumbItem[] = Array.isArray(labelOrItems)
                ? labelOrItems
                : [{ label: labelOrItems }];

            breadcrumbs.push(
                ...itemsToAdd.map((bc) => ({
                    label: bc.label,
                    path: bc.path || path
                }))
            )
        }

        return this.createBreadcrumbs(child, path, breadcrumbs);
    }
}
