<div class="sidebar" [class.opened]="!isMobile || displayIcons">
    <h1
        class="cursor-pointer text-color"
        (click)="isMobile ? (displayIcons = !displayIcons) : null"
    >
        BM
    </h1>
    <div class="sidebar__icons" *ngIf="!isMobile || displayIcons">
        <app-sidebar-icons />
    </div>
</div>
