import { AuthOutput } from '../dtos/outputs/auth.output';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import AuthActions from '../actions/auth.actions';

export const authStateKey = 'AuthState';

export interface AuthState extends Partial<AuthOutput> {
    connected: boolean;
}

export const initialState: AuthState = {
    connected: false,
};

const reducer: ActionReducer<AuthState> = createReducer(
    initialState,
    on(AuthActions.loginSuccess, (_, { auth }) => ({
        connected: true,
        ...auth,
    })),
    on(AuthActions.disconnect, () => initialState)
);

export const authReducer = (state: AuthState | undefined, action: Action) =>
    reducer(state, action);
