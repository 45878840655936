import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () =>
            import('./dashboard/dashboard.module').then(
                (m) => m.DashboardModule
            ),
        data: {
            breadcrumb: 'Dashboard',
            color: 'jade',
            module: 'dashboard',
        },
    },
    {
        path: 'livres',
        loadChildren: () =>
            import('./book/book.module').then((m) => m.BookModule),
        data: {
            breadcrumb: 'Livres',
            color: 'sapphire',
            module: 'book',
        },
    },
    {
        path: 'scraper',
        loadChildren: () =>
            import('./scraper/scraper.module').then((m) => m.ScraperModule),
        data: {
            breadcrumb: 'Scraper',
            color: 'amethyst',
            module: 'scraper',
        },
    },
    {
        path: 'logs',
        loadChildren: () =>
            import('./logs/logs.module').then((m) => m.LogsModule),
        data: {
            breadcrumb: 'Logs',
            color: 'ruby',
            module: 'logs',
        },
    },
    {
        path: 'dev',
        loadChildren: () => import('./dev/dev.module').then((m) => m.DevModule),
        data: {
            breadcrumb: 'Développeurs',
            color: 'citrine',
            module: 'dev',
        },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
