import {
    CUSTOM_ELEMENTS_SCHEMA,
    NgModule,
    isDevMode,
    inject,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserModule } from './user/user.module';
import { SharedModule } from './shared/shared.module';
import { NgxHttpHelperModule } from '@innova2/ngx-http-helper';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UserFeatureSelectors } from './user/user.store';
import { NgChartsModule } from 'ng2-charts';
import { httpConstants } from '@portals/constants';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SWAL_TOAST } from './shared/constants';
import { throwError } from 'rxjs';
import { OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        StoreModule.forRoot({}, {}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: !isDevMode(),
        }),
        NgxHttpHelperModule.forRoot({
            authenticators: [
                {
                    scheme: 'Bearer',
                    tokenSelector: () =>
                        inject(Store).select(UserFeatureSelectors.selectToken),
                },
            ],
            client: {
                baseUrl: environment.baseUrl,
                catch: (err) => {
                    const code = err.error?.code || 'Erreur inconnue';
                    const detail = err.error.detail || 'Veuillez réessayer.';

                    const additionalDetail = code === 'ERR_BAD_REQUEST'
                        ? 'Items: [' + err.error.items.join(', ') + ']'
                        : '';

                    const title = [detail, additionalDetail].join(' ');
                    const description = ['Code', code].join(' : ');

                    SWAL_TOAST.fire({
                        title,
                        text: description,
                        icon: 'error',
                        timer: 10000,
                    });

                    return throwError(() => err);
                }
            },
        }),
        SweetAlert2Module.forRoot(),
        UserModule,
        SharedModule,
        NgChartsModule,
    ],
    providers: [{
        provide: httpConstants.DI_TOKEN_API_URL,
        useValue: environment.baseUrl,
    }, {
        provide: HIGHLIGHT_OPTIONS,
        useValue: {
            coreLibraryLoader: () => import('highlight.js/lib/core'),
            lineNumbersLoader: () => import('ngx-highlightjs/line-numbers'),
            languages: {
                json: () => import('highlight.js/lib/languages/json')
            },
            themePath: 'assets/atom-one-dark.css',
        }
    }, {
        provide: OWL_DATE_TIME_LOCALE,
        useValue: 'fr'
    }],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}
