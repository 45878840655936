import Swal from 'sweetalert2';

export const LS_AUTH = 'auth';

export const DEFAULT_DEBOUNCE_TIME = 500;

export const SWAL_TOAST = Swal.mixin({
    showConfirmButton: false,
    showCancelButton: false,
    timer: 4000,
    timerProgressBar: true,
    toast: true,
    position: 'top-end'
});

export const URL_DECITRE = 'https://www.decitre.fr/livres';
