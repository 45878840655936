<div
    class="profile flex flex-space-between flex-align-center"
    *ngIf="user$ | async as user"
>
    <p class="profile__role">
        {{ user.loaded ? user.role?.label : 'Chargement...' }}
    </p>
    <span class="profile__separator weight-light">|</span>
    <ion-icon class="cursor-pointer" name="reader-outline"></ion-icon>
    <span class="profile__separator weight-light">|</span>
    <ion-icon
        class="cursor-pointer text-color color-default"
        name="hourglass-outline"
        [class.color-danger]="countdownAlmostDone"
        (click)="showCountdown = !showCountdown"
    ></ion-icon>
    <countdown
        class="profile__countdown text-color color-default cursor-pointer"
        [class.hide]="!showCountdown"
        [class.color-danger]="countdownAlmostDone"
        [config]="countdownConfig"
        (event)="handleCountdown($event)"
        (click)="showCountdown = false"
    />
    <span class="profile__separator weight-light">|</span>
    <ion-icon
        #trigger
        cdkOverlayOrigin
        class="profile__user-icon text-color cursor-pointer"
        name="person-circle-outline"
        (click)="isProfileOverlayOpen = !isProfileOverlayOpen"
    ></ion-icon>

    <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isProfileOverlayOpen"
    >
        <app-popover title="Mon compte" [offset]="15">
            <p>{{ user.firstName }} {{ user.lastName?.at(0) }}.</p>
            <p
                class="text-color color-danger cursor-pointer"
                (click)="disconnect()"
            >
                Se déconnecter
            </p>
        </app-popover>
    </ng-template>
</div>
