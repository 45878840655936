import { createAction, props } from '@ngrx/store';
import { AuthInput } from '../dtos/inputs/auth.input';
import { AuthOutput } from '../dtos/outputs/auth.output';

const login = createAction('auth/login', props<{ auth: AuthInput }>());
const loginSuccess = createAction(
    'auth/loginSuccess',
    props<{ auth: AuthOutput }>()
);

const disconnect = createAction('auth/disconnect');

export default {
    login,
    loginSuccess,
    disconnect,
};
