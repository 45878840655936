import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import AuthActions from '../../actions/auth.actions';
import { AuthInput } from '../../dtos/inputs/auth.input';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    form = new FormGroup({
        email: new FormControl('', { nonNullable: true }),
        password: new FormControl('', { nonNullable: true }),
    });

    constructor(private store: Store) {}

    login() {
        if (this.form.valid) {
            this.store.dispatch(
                AuthActions.login({ auth: this.form.value as AuthInput })
            );
        }
    }
}
