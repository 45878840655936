<ng-container *ngIf="authenticatedUser$ | async as auth">
    <app-login *ngIf="!auth.connected"></app-login>

    <div class="page" *ngIf="auth.connected">
        <app-sidebar class="page__sidebar" />

        <div class="page__container">
            <app-header-toolbar />
            <router-outlet />
        </div>
    </div>
</ng-container>
