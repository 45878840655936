import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterBy',
})
export class FilterByPipe implements PipeTransform {
    static isFoundOnWalking(value: any, key: any) {
        let walker = value;
        let found = false;
        do {
            if (
                walker.hasOwnProperty(key) ||
                Object.getOwnPropertyDescriptor(walker, key)
            ) {
                found = true;
                break;
            }
            // tslint:disable-next-line:no-conditional-assignment
        } while ((walker = Object.getPrototypeOf(walker)));

        return found;
    }

    static isNumber(value: any) {
        return !isNaN(parseInt(value, 10)) && isFinite(value);
    }

    /**
     * Checks function's value if type is function otherwise same value
     */
    static getValue(value: any): any {
        return typeof value === 'function' ? value() : value;
    }

    static normalizeString(value: string) {
        return value
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
    }

    private filterByString(filter: any) {
        return (value: any) =>
            !filter ||
            (value
                ? ('' + FilterByPipe.normalizeString(value)).indexOf(
                      FilterByPipe.normalizeString(filter)
                  ) !== -1
                : false);
    }

    private filterByBoolean(filter: any) {
        return (value: any) => Boolean(value) === filter;
    }

    private filterByObject(filter: any) {
        return (value: any) => {
            // tslint:disable-next-line:forin
            for (const key in filter) {
                if (key === '$or') {
                    if (
                        !this.filterByOr(filter.$or)(
                            FilterByPipe.getValue(value)
                        )
                    ) {
                        return false;
                    }
                    continue;
                }

                if (
                    !value ||
                    !FilterByPipe.isFoundOnWalking(value, key) ||
                    !this.isMatching(
                        filter[key],
                        FilterByPipe.getValue(value[key])
                    )
                ) {
                    return false;
                }
            }

            return true;
        };
    }

    private filterByArray(filter: any) {
        return (value: any) => {
            for (const key in filter) {
                if (!Array.isArray(filter[key])) {
                    continue;
                }
                if (
                    value.some((item: any[]) =>
                        filter[key]
                            .map((c: any) => FilterByPipe.normalizeString(c))
                            .includes(item[key as any])
                    )
                ) {
                    return true;
                }
            }

            return false;
        };
    }

    private isMatching(filter: any, val: any) {
        switch (typeof filter) {
            case 'boolean':
                return this.filterByBoolean(filter)(val);
            case 'string':
                return this.filterByString(filter)(val);
            case 'object':
                if (Array.isArray(val)) {
                    return this.filterByArray(filter)(val);
                }
                return this.filterByObject(filter)(val);
        }

        return this.filterDefault(filter)(val);
    }

    /**
     * Filter value by $or
     */
    private filterByOr(filter: any[]): (value: any) => boolean {
        return (value: any) => {
            const length = filter.length;

            const arrayComparison = (i: number) =>
                value.indexOf(filter[i]) !== -1;
            const otherComparison = (i: number) =>
                this.isMatching(filter[i], value);
            const comparison = Array.isArray(value)
                ? arrayComparison
                : otherComparison;

            for (let i = 0; i < length; i++) {
                if (comparison(i)) {
                    return true;
                }
            }

            return false;
        };
    }

    /**
     * Default filterDefault function
     */
    private filterDefault(filter: any): (value: any) => boolean {
        return (value: any) => filter === undefined || filter === value;
    }

    transform(array: any[] | null, filter: any): any {
        if (!array) {
            return array;
        }

        switch (typeof filter) {
            case 'boolean':
                return array.filter(this.filterByBoolean(filter));
            case 'string':
                if (FilterByPipe.isNumber(filter)) {
                    return array.filter(this.filterDefault(filter));
                }
                return array.filter(this.filterByString(filter));
            case 'object':
                return array.filter(this.filterByObject(filter));
            case 'function':
                return array.filter(filter);
        }
        return array.filter(this.filterDefault(filter));
    }
}
