import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { exhaustMap, tap } from 'rxjs';
import AuthActions from '../actions/auth.actions';
import UserActions from '../actions/user.actions';
import { LS_AUTH } from '../../shared/constants';

const login = createEffect(
    (actions$ = inject(Actions), authService = inject(AuthService)) =>
        actions$.pipe(
            ofType(AuthActions.login),
            exhaustMap(({ auth }) =>
                authService.create(auth).pipe(
                    tap((output) =>
                        localStorage.setItem(LS_AUTH, JSON.stringify(output))
                    ),
                    exhaustMap((output) => [
                        AuthActions.loginSuccess({ auth: output }),
                        UserActions.getMe(),
                    ])
                )
            )
        ),
    { functional: true }
);

export default {
    login,
};
