import { Component } from '@angular/core';
import { Module } from '../../../utils';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';

@Component({
    selector: 'app-sidebar-icons',
    templateUrl: './sidebar-icons.component.html',
    styleUrls: ['./sidebar-icons.component.scss'],
})
export class SidebarIconsComponent {
    module: Module = 'dashboard';

    constructor(private route: ActivatedRoute, private router: Router) {}

    ngOnInit(): void {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.route.root.children),
                map((children) =>
                    children.length > 0
                        ? children[0].snapshot.data
                        : { module: 'dashboard' }
                )
            )
            .subscribe(({ module }) => {
                this.module = module;
            });
    }
}
