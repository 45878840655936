<div class="sidebar__icons flex flex-column flex-align-center">
    <ion-icon
        name="stats-chart-outline"
        class="sidebar__icons__icon cursor-pointer"
        [class.sidebar__icons__icon--activated]="module === 'dashboard'"
        routerLink=""
    ></ion-icon>
    <ion-icon
        name="book-outline"
        class="sidebar__icons__icon cursor-pointer"
        [class.sidebar__icons__icon--activated]="module === 'book'"
        routerLink="/livres"
    ></ion-icon>
    <ion-icon
        name="earth-outline"
        class="sidebar__icons__icon cursor-pointer"
        [class.sidebar__icons__icon--activated]="module === 'scraper'"
        routerLink="/scraper"
    ></ion-icon>
    <ion-icon
        name="file-tray-full-outline"
        class="sidebar__icons__icon cursor-pointer"
        [class.sidebar__icons__icon--activated]="module === 'logs'"
        routerLink="/logs"
    ></ion-icon>
    <ion-icon
        name="people-outline"
        class="sidebar__icons__icon cursor-pointer"
        [class.sidebar__icons__icon--activated]="module === 'dev'"
        routerLink="/dev"
    ></ion-icon>
</div>
