<div class="login">
    <form class="login__form" [formGroup]="form" (ngSubmit)="login()">
        <div class="login__form__header">
            <h1 class="login__form__header__title">BooksMemory</h1>
            <p class="login__form__header__sub-title">Portail administrateur</p>
        </div>
        <div class="login__form__content">
            <label for="email" class="weight-semi-bold">Adresse email :</label>
            <input
                type="email"
                id="email"
                placeholder="Email"
                formControlName="email"
                required
            />
            <label for="password" class="weight-semi-bold"
                >Mot de passe :</label
            >
            <input
                type="password"
                id="password"
                placeholder="Mot de passe"
                formControlName="password"
                required
            />
        </div>
        <div class="login__form__footer">
            <button type="submit" class="cursor-pointer text-upper">
                Se connecter
            </button>
        </div>
    </form>
</div>
