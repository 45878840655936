import { Injectable } from '@angular/core';
import { RestService } from '@innova2/ngx-http-helper';
import { AuthInput } from '../../dtos/inputs/auth.input';
import { AuthOutput } from '../../dtos/outputs/auth.output';

@Injectable({
    providedIn: 'root',
})
export class AuthService extends RestService<AuthOutput, AuthInput> {
    protected override readonly resourceUri = 'auth';
}
