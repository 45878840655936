import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UserFeatureSelectors } from './user/user.store';
import { AuthState } from './user/reducers/auth.reducer';
import AuthActions from './user/actions/auth.actions';
import UserActions from './user/actions/user.actions';
import { LS_AUTH } from './shared/constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    authenticatedUser$: Observable<AuthState>;

    constructor(
        private renderer: Renderer2,
        private store: Store,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {
        this.checkAlreadyAuthenticated();
        this.listenLogin();
        this.listenRouteChange();
    }

    checkAlreadyAuthenticated() {
        const auth = localStorage.getItem(LS_AUTH);
        if (auth) {
            this.store.dispatch(
                AuthActions.loginSuccess({ auth: JSON.parse(auth) })
            );
            this.store.dispatch(UserActions.getMe());
        }
    }

    listenRouteChange() {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.route.root.children),
                map((children) =>
                    children.length > 0
                        ? children[0].snapshot.data
                        : { color: 'jade' }
                )
            )
            .subscribe(({ color }) => {
                document.body
                    .getAttribute('class')
                    ?.split(' ')
                    .filter((c) => c.startsWith('color-'))
                    .forEach((c) => {
                        this.renderer.removeClass(document.body, c);
                    });

                this.renderer.addClass(document.body, 'color-' + color);
            });
    }

    listenLogin() {
        this.authenticatedUser$ = this.store.select(
            UserFeatureSelectors.selectAuth
        );
    }
}
