import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { exhaustMap } from 'rxjs';
import { UserService } from '../services/user/user.service';
import UserActions from '../actions/user.actions';

const getMe = createEffect(
    (actions$ = inject(Actions), userService = inject(UserService)) =>
        actions$.pipe(
            ofType(UserActions.getMe),
            exhaustMap(() => userService.findById('me')),
            exhaustMap((user) => [UserActions.getMeSuccess({ user })])
        ),
    { functional: true }
);

export default {
    getMe,
};
