import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { User } from '../dtos/user';
import UserActions from '../actions/user.actions';

export const userStateKey = 'UserState';

export interface UserState extends Partial<User> {
    loaded: boolean;
}

export const initialState: UserState = {
    loaded: false,
};

const reducer: ActionReducer<UserState> = createReducer(
    initialState,
    on(UserActions.getMeSuccess, (_, { user }) => ({
        loaded: true,
        ...user,
    })),
    on(UserActions.reset, () => initialState)
);

export const userReducer = (state: UserState | undefined, action: Action) =>
    reducer(state, action);
