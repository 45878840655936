import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState, authStateKey } from '../reducers/auth.reducer';
import jwt_decode, { JwtPayload } from 'jwt-decode';

const selectState = createFeatureSelector<AuthState>(authStateKey);

const isConnected = createSelector(selectState, (state) => state.connected);
const selectToken = createSelector(
    selectState,
    (state) => state.accessToken || ''
);
const selectTokenExpiration = createSelector(selectToken, (accessToken) => {
    const decoded = jwt_decode<JwtPayload>(accessToken);
    return decoded.exp || 0;
});

export default {
    selectState,
    isConnected,
    selectToken,
    selectTokenExpiration,
};
