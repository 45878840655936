<div class="breadcrumb text-color">
    <p
        class="breadcrumb__item cursor-pointer"
        *ngFor="let item of items; let last = last"
        [routerLink]="item.path"
    >
        {{ item.label }}
        <span class="breadcrumb__item__separator" *ngIf="!last">/</span>
    </p>

    <p class="breadcrumb__item" *ngIf="!items.length">Chargement...</p>
</div>
