import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authReducer, AuthState, authStateKey } from './reducers/auth.reducer';
import AuthEffects from './effects/auth.effects';
import AuthSelectors from './selectors/auth.selectors';
import { userReducer, UserState, userStateKey } from './reducers/user.reducer';
import UserEffects from './effects/user.effects';
import UserSelectors from './selectors/user.selectors';

export const userFeatureKey = 'User';

export interface UserFeatureState {
    [authStateKey]: AuthState;
    [userStateKey]: UserState;
}

export const userFeatureReducers = {
    [authStateKey]: authReducer,
    [userStateKey]: userReducer,
};

export const userFeatureEffects = [AuthEffects, UserEffects];

const selectState = createFeatureSelector<UserFeatureState>(userFeatureKey);
const selectUser = createSelector(selectState, UserSelectors.selectState);
const selectAuth = createSelector(selectState, AuthSelectors.selectState);
const selectToken = createSelector(selectState, AuthSelectors.selectToken);
const selectTokenExpiration = createSelector(
    selectState,
    AuthSelectors.selectTokenExpiration
);

export const UserFeatureSelectors = {
    selectState,
    selectUser,
    selectAuth,
    selectToken,
    selectTokenExpiration,
};
