import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderToolbarComponent } from './components/header-toolbar/header-toolbar.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ProfileToolbarComponent } from './components/profile-toolbar/profile-toolbar.component';
import { RouterModule } from '@angular/router';
import { SidebarIconsComponent } from './components/sidebar-icons/sidebar-icons.component';
import { LinkButtonComponent } from './components/link-button/link-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CountdownComponent } from 'ngx-countdown';
import { OverlayModule } from '@angular/cdk/overlay';
import { PopoverComponent } from './components/popover/popover.component';
import { FilterByPipe } from './pipes/filter-by/filter-by.pipe';
import { StickyToolbarComponent } from './components/sticky-toolbar/sticky-toolbar.component';
import {
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';

@NgModule({
    declarations: [
        SidebarComponent,
        HeaderToolbarComponent,
        BreadcrumbComponent,
        ProfileToolbarComponent,
        SidebarIconsComponent,
        LinkButtonComponent,
        PopoverComponent,
        FilterByPipe,
        StickyToolbarComponent,
    ],
    providers: [FilterByPipe],
    exports: [
        SidebarComponent,
        HeaderToolbarComponent,
        LinkButtonComponent,
        FilterByPipe,
        StickyToolbarComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        NgSelectModule,
        CountdownComponent,
        OverlayModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        ReactiveFormsModule,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
